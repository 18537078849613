:root {
    --mantine-color-scheme: 'light' !important;
    --main-color: #02547C;
    --alt-color: #0b4667;
    --base-gray: #f1f1f1;
    --off-gray: #e9e9e9;
    --item-gray: #9D9D9D;
    --text-gray: #5f6368;
    --trans: #ffffff00;
    --px11: 0.6875rem;
    --px12: 0.75rem;
    --px13: 0.813rem;
    --px14: 0.875rem;
    --px15: 0.9375rem;
    --px16: 1rem;
    --px17: 1.0625rem;
    --px18: 1.125rem;
    --px19: 1.1875rem;
    --px20: 1.25rem;
    --px21: 1.3125rem;
    --px22: 1.375rem;
    --px23: 1.4375rem;
    --px24: 1.5rem;
    --px25: 1.5625rem;
    --titleFont: 2.2rem;
    --hoverBorder: rgb(231, 231, 231);
    --hoverBorder: rgba(231, 231, 231, 0.068);
    --frostedBorder: solid 1.5px #ffffff14;
    --heroFont: 3.6rem;
    --frostedBackground: rgba(255, 255, 255, 0.071);
    --grayBackground: #f1f3f5;
    --gradientedFrostedBorder: solid 1.5px;
    --frostedText: rgba(255, 255, 255, 0.7);
    --hoverBorder: rgb(231, 231, 231);
}



* {
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;

    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
}

button {
    cursor: pointer;
}

body {
    background-color: #F8F8F8 !important;
}

.material-icons {
    font-family: 'Material Icons Round' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.hero-wrapper {
    background-image: url('https://images.unsplash.com/photo-1613575573097-15f53a39267a?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
    background-size: cover;
}

.hero {
    width: 100%;
    height: 75vh;
    background: linear-gradient(86deg, #01486be6 4.35%, #01547ef8 81.83%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;


}

a {
    text-decoration: none;
    color: inherit;
}

.hero>h1 {
    color: #ffffff;
    font-size: 3.5rem;
    font-weight: 700;

    text-align: center;
    line-height: 70px;
    margin-top: 20px;


}

.hero>p {
    color: #ffffff;
    font-size: var(--px16);
    font-weight: 500;
    text-align: center;
    line-height: 30px;
}

.hero>span {
    color: #fff;
    font-size: var(--px14);
    font-weight: 500;
    text-align: center;
    line-height: 30px;
}

.hero>h5 {
    border: solid 1px #ffffff17;
    color: white;
    font-weight: 500;
    font-size: var(--px12);
    padding: 9px 19px;
    border-radius: 50px;
    margin-top: 20px;
}

.hero-btn {
    display: flex;
    align-items: center;
    gap: 14px;
    font-weight: 500;
    font-size: var(--px16);
    color: white;
    background-color: #00000027;
    padding: 15px 23px;
    border: none;
    border-radius: 10px;
    margin: 8px 0px;
    transition: 0.2s ease;
    backdrop-filter: blur(10px);

}

.hero-btn>img {
    width: 18px;
}

.hero-btn:hover {
    background-color: white;
    color: black;
}


.hero-btn:hover>img {
    filter: invert();
}

.navbar {
    position: fixed;
    top: 0px;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 9999;
    justify-content: center;
    align-items: center;

    display: grid;
    grid-template-columns: 200px 1fr 200px;
    padding: 20px;



}

.scrolled-nav {
    background-color: var(--main-color);
    backdrop-filter: blur(10px);

}

.navbar>a>img {
    width: 150px;
    cursor: pointer;
}

.nav-links {
    display: flex;
    list-style: none;
    align-items: center;
    gap: 20px;
    justify-content: center;
}

.nav-links>li>a {
    color: white;
    font-weight: 500;
    font-size: var(--px15);
}

.feature-wrapper {
    margin: auto;
    width: 690px;
    margin: 100px auto
}

.feature-wrapper>h1 {
    text-align: center;
    color: black;
    margin-bottom: 10px;
}

.feature-wrapper>p {
    text-align: center;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.723)
}

.feature-blocks {
    display: flex;
    margin: auto;
    width: 80%;
    gap: 5%;
    max-width: 1100px
}

.feature-block {

    border-radius: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    background-color: white;
}

.feature-block>img {
    width: 150px;
}

.feature-block>h2 {
    color: black;
    font-size: var(--px25);
    margin-top: 10px;
    text-align: center;
    line-height: 35px;
    font-weight: 600;
}

.feature-block>p {

    font-size: var(--px15);
    margin-top: 10px;
    text-align: center;
    line-height: 25px;

}

.feature-block-grouper {
    display: flex;
    flex-direction: column;
    gap: 45px
}

.chip-content {
    display: flex;
    align-items: center;
    gap: 20px;
}

.feature-chip {
    background-color: white;
    ;
    padding: 21px 35px;
    border-radius: 100px;
    padding-left: 22px;
}

.chip-content>span {
    font-size: var(--px17);

    font-weight: 600;
}

.chip-content>span>p {
    font-size: var(--px13);
    color: var(--item-gray);
    font-weight: 500;
    margin-top: 4px;

}

.chip-content>img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
}

.feature-chips {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: 80%;
    gap: 25px;
    max-width: 1000px;
    justify-content: center;
    margin-bottom: 100px
}




.mobile-navbar {
    display: none;
}



.video-block {
    margin: auto;
    width: 80%;
    max-width: 1100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3.5%;
    margin-bottom: 30px;

}

.video-block-grouper {
    margin-top: 40px;
}

.video-block>video {
    width: 100%;
    height: 320px;
    border-radius: 20px;
    object-fit: cover;
}

.video-block>img {
    width: 100%;
    height: 320px;
    border-radius: 20px;
    object-fit: cover;
}

.video-block-text {
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;

    justify-content: center;
    gap: 7px;
    padding: 0px 55px
}

.video-block-text>h2 {
    font-size: var(--px25);
    font-weight: 600;
    color: black;
}

.video-block-text>p {
    font-size: var(--px15);
    line-height: 30px;
}





.link-strip {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
}


.link-strip>a>img {
    width: 25px;


    cursor: pointer;

}

.link-strip>a {
    display: flex;
    align-items: center;
    color: white;
    gap: 10px;

}

.link-strip>a>p {
    font-size: var(--px14);
    font-weight: 500;
}




















.compact-feature-blocks {
    margin-bottom: 100px;
    margin-top: 50px;
}

.compact-block-wrapper {

    display: flex;
    margin: auto;
    width: 80%;
    gap: 5%;
    max-width: 1000px;
    margin-bottom: 35px;


}

.compact-block {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 30px;
    background-color: white;
    border-radius: 20px;
    width: 100%;
    cursor: pointer;
}

.compact-block:focus {
    outline: var(--frostedBorder)
}

.compact-block-text>h2 {
    font-size: var(--px25);
    font-weight: 600;
    line-height: 30px;
}

.compact-block-text>p {
    color: rgba(0, 0, 0, 0.723);
    font-size: var(--px14);
    line-height: 25px
}













.footer {
    background-color: var(--main-color);
    color: white;
    padding: 50px 90px;
    display: flex;
    flex-direction: column;
    gap: 5px;




}

.footer-content {
    display: flex;
    justify-content: space-between;

}


.footer-promo>h1 {

    margin-bottom: 7px;
}

.footer-promo>p {
    font-size: var(--px15);
    margin-bottom: 30px;

}

.footer-links {
    display: flex;
    align-items: center;
}

.footer-links>ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    list-style-type: none;

}

.footer-footer>p {
    font-size: var(--px14);
    margin-top: 20px;
    text-align: center;
    opacity: 0.8;
    ;

}





.release-container {
    margin: 30px auto;
    width: 800px;
    display: flex;
    flex-direction: column;
    gap: 20px;

}


.release {
    padding: 35px;
    display: grid;
    grid-template-columns: 1fr;
    border-radius: 20px;
    background-color: white;

}

.release-btn {
    display: flex;
    justify-content: center;
    align-items: center;

}

.download-btn {
    background-color: transparent;
    border: var(--frostedBorder);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 50%;
}

.download-btn>i {
    font-size: 1.7rem;


}

.release-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 140px;
    white-space: nowrap;
    justify-content: center;
}

.release-info>h1 {
    font-size: var(--px25);
    font-weight: 600;
    margin: 10px 0px
}

.release-info>p {
    font-size: var(--px14);
    opacity: 0.6;
    margin-top: 10px;
    line-height: 28px;
    display: flex;
    flex-direction: column;
    gap: 13px;

}

.release-icon>span {
    font-size: var(--px16);
    font-weight: 500;
    margin-top: 5px;
}

.release-info>span {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 7px;
    display: block;
    color: rgba(0, 0, 0, 0.617);
}

.release-info>span>span {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 7px;
    margin-right: 5px;

}

.release-icon>i {
    font-size: 1.7rem;

}











.browser-selector {
    display: flex;

    gap: 12px;
    height: 65px;
    align-items: center;
    background-color: var(--frostedBackground);
    padding: 15px;
    border-radius: 12px;
    border: var(--frostedBorder);
    cursor: pointer;
}



.browser-selector>button>img {
    width: 35px;
    height: 35px;
    padding: 7px;
    background-color: white;
    border-radius: 6px;
    object-fit: scale-down;

}

.browser-switcher {

    gap: 13px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
}

.browser-selector-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.browser-selector-text>h4 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: -2px;
}

.browser-selector-text>p {
    font-size: 11px;
    opacity: 0.4;
}

.launch-btn {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--frostedBackground);
    border: var(--frostedBorder);
    border-radius: 7px;
    margin-left: 7px;
    cursor: pointer;
}

.launch-btn>i {
    font-size: 20px;
    color: white;
    opacity: 0.85;
}

.expand-browser {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    ;
    height: 60px;
    width: 40px;
    margin-right: -15px;
    border: none;
    margin-left: -12px;
    cursor: pointer;
}


.hero-btn[aria-expanded="true"] {
    background-color: white;
    color: black;
}

.hero-btn[aria-expanded="true"]>img {
    filter: invert();
}


.mantine-Popover-dropdown {
    background-color: #00000027 !important;
    border: none !important;
    border-radius: 12px !important;
    backdrop-filter: blur(20px) !important;
    margin-bottom: 10px;
    box-shadow: none !important;
}

.browser-options>a {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    gap: 8px;
    font-weight: 600;
    font-size: 14px;
    border-bottom: var(--frostedBorder);
    padding-bottom: 11px;
    padding-top: 11px;
    justify-content: space-between;
    width: 100%;
}

.browser-options>a>i {
    font-size: 20px;
    color: white;
    opacity: 0.55;

}

.browser-options>a:last-of-type {
    border-bottom: none;
    padding-bottom: 0px;
}

.browser-options>a:first-of-type {
    padding-top: 0px;

}

.menu-text {
    display: flex;
    align-items: center;
    gap: 5px;
}

.menu-text>img {
    width: 25px;
    height: 25px;
    padding: 3px;

    border-radius: 6px;
    object-fit: scale-down;

}

.menu-text>p {
    font-size: 14px;
    font-weight: 600;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
}

.menu-text>p>span {
    font-size: 11px;
    opacity: 0.4;
    margin-top: -3px;
}

.expand-browser>i {
    font-size: 22px;
    color: white;
    opacity: 0.55;
}




.browser-options>p {
    color: white;
    width: 70%;

}

.browser-options>p:first-of-type {
    font-size: 19px;
}

.browser-options>pre {
    margin-top: 15px;
    font-size: 13px;

    text-align: center;
    color: white;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.062);
    font-weight: 500;

}

.browser-options {
    padding: 10px;
}

.browser-title>p {
    font-size: 18px;
    font-weight: 700;
    color: white;
}

.browser-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.browser-title>button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-weight: 600;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    ;

}

.browser-title>button>i {
    font-size: 20px;
    margin-right: 5px;
}


@media (max-width: 900px) {
    .link-strip {
        position: absolute;
        bottom: 0px;
        display: none;
    }

    .video-block-text {
        padding: 36px;
        margin: 26px 0px;
    }

    .compact-block-wrapper {
        flex-direction: column;
        gap: 30px;
    }

    .compact-block {
        flex-direction: column;
    }

    .video-block {
        display: flex;
        flex-direction: column;
    }

    .footer-content {
        flex-direction: column;
        gap: 20px;
    }

    .hero {
        height: 100vh;
    }

    .hero>h1 {

        font-size: 2rem;
        line-height: inherit;
        margin: 0px 38px;
    }

    .hero>p {
        font-size: var(--px14);
        line-height: 25px;
    }



    .hero>span {
        font-size: var(--px13);
        line-height: 25px;
    }

    .navbar {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 20px;
    }

    ::marker {
        display: none !important;
        opacity: 0;
    }

    .feature-wrapper {
        margin: 50px 40px;
        width: auto
    }

    .feature-wrapper>h1 {
        font-size: var(--px24);
    }

    .feature-wrapper>p {
        font-size: var(--px15);
        line-height: 30px
    }

    .feature-blocks {
        flex-direction: column;
        gap: 20px;
    }

    .feature-block {
        padding: 20px;
    }

    .feature-block>img {
        width: 100px;
    }

    .feature-block>h2 {
        font-size: var(--px21);
    }

    .feature-block>p {
        font-size: var(--px16);
    }

    .feature-chips {
        gap: 10px;
    }

    .feature-chip {
        width: 100%;
    }

    .chip-content {
        display: flex;
        ;
    }

    .chip-content>span {
        font-size: var(--px15);
    }

    .chip-content>img {
        width: 50px;
        height: 50px;
    }

    .feature-chip {
        padding: 15px 25px;
    }
}